import React, { useState } from 'react';
import './App.css';
import Splat from './Splat';
import Canvas from './Canvas';

function App() {
  const [currentPage, setCurrentPage] = useState('home'); // State to track the current page

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return <Canvas />;
      case 'splat':
        return <Splat/>;
      default:
        return <Canvas />;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Draw a leetle pictures :)))</h1>
        <p>Web development arargggg</p>
        <nav>
          <button onClick={() => setCurrentPage('home')}>Home</button>
          <button onClick={() => setCurrentPage('splat')}>Splat</button>
        </nav>
      </header>
      <main>
        {renderPage()} {/* This will render the current page based on the state */}
      </main>
    </div>
  );
}

export default App;