import React, { useEffect, useRef, useState } from 'react';

const TILE_SIZE = 20; // Each tile will be 40x40 pixels
const GRID_SIZE = 40; // 40x40 grid
const CANVAS_SIZE = TILE_SIZE * GRID_SIZE;

const getRandomColor = () => {
  // Generate a random color (hue from 0 to 255 with max saturation)
  const hue = Math.floor(Math.random() * 254 + 1);
  return hue;
};

const Canvas = () => {
  const canvasRef = useRef(null);
  const [tiles, setTiles] = useState(Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(0)));

  const handleClick = (x, y) => {
    const newColor = getRandomColor();
    const newTiles = [...tiles];
    newTiles[y][x] = newColor; // Update the color of the clicked tile

    // Update the state
    setTiles(newTiles);

    // Send the color and coordinates to the server (replace with your API endpoint)
    fetch('/api/draw', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ x, y, color: newColor }),
    });
  };

  const handleRightClick = (e, x, y) => {
    e.preventDefault(); // Prevent the context menu from showing
    const newTiles = [...tiles];
    newTiles[y][x] = 0; // Clear the tile (set it back to 0)

    // Update the state
    setTiles(newTiles);

    // Send the clear action to the server
    fetch('/api/draw', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ x, y, color: 0 }),
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Draw the grid
    const drawGrid = () => {
      for (let y = 0; y < GRID_SIZE; y++) {
        for (let x = 0; x < GRID_SIZE; x++) {
          if(tiles[y][x] !== 0)
            ctx.fillStyle = `hsl(${tiles[y][x]}, 100%, 50%)`; // Default to white if no color is set
          else
            ctx.fillStyle = 'white';

          ctx.fillRect(x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, TILE_SIZE);
          ctx.strokeRect(x * TILE_SIZE, y * TILE_SIZE, TILE_SIZE, TILE_SIZE); // Draw tile border
        }
      }
    };

    drawGrid();
  }, [tiles]); // Re-draw when tiles change

  const handleMouseClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / TILE_SIZE);
    const y = Math.floor((e.clientY - rect.top) / TILE_SIZE);
    handleClick(x, y);
    e.preventDefault();
  };

  const handleMouseRightClick = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left) / TILE_SIZE);
    const y = Math.floor((e.clientY - rect.top) / TILE_SIZE);
    handleRightClick(e, x, y);
  };

  useEffect(() => {
   const ws = new WebSocket('wss://elextronics.com'); // Replace with your server's address if needed

   ws.onmessage = (event) => {
     const data = JSON.parse(event.data);
     if (data.tiles) {
       setTiles(data.tiles); // Update the tiles state with the new grid
     }
   };

   return () => {
      ws.close(); // Clean up the WebSocket connection on unmount
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={CANVAS_SIZE}
      height={CANVAS_SIZE}
      onClick={handleMouseClick}
      onContextMenu={handleMouseRightClick}
      style={{ border: '1px solid black' }}
    />
  );
};

export default Canvas;
