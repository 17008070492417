import React, { useState, useEffect } from 'react';

const Splat = () => {
    const [image, setImage] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', image);

        await fetch('/api/upload', {
            method: 'POST',
            body: formData
        });
    };

    // Function to fetch the image
    const fetchImage = async () => {
        const response = await fetch('/api/image');
        if (response.ok) {
            const blob = await response.blob(); // Convert to a blob
            setImageSrc(URL.createObjectURL(blob)); // Set the image URL to display
        }
    };

    // Fetch the image every 5 seconds
    useEffect(() => {
        fetchImage(); // Fetch immediately when the component mounts
        const interval = setInterval(fetchImage, 5000); // Fetch every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, []);

    return (
        <div>
            <h1>The time is right...</h1>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Supply image</button>

            {imageSrc ? (
                <img src={imageSrc} alt="Uploaded" style={{ maxWidth: '100%' }} />
            ) : (
                <p>No image </p>
            )}
        </div>
    );
};

export default Splat;